.viewer.mobile .guid {
    position: fixed;
    right: -20px;
    bottom: 0;
    width: 150px;
}

.viewer.mobile.guid_grid {
    display: block;
}

.viewer.mobile .showcase_container_row {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.viewer.mobile .triangle {
    display: none;
}

.viewer.mobile .showcase_controls_row {
    position: absolute;
    bottom: 0;
    background-color: white;
    padding: 0 130px 0 25px;
    right: 0;
}

.viewer.mobile .guid_content {
    position: fixed;
    bottom: 40px;
    right: 10px;
}

.viewer.mobile .guid_content {
    padding: 5px 5px 0 5px
}

.viewer.mobile .guid_content.fullscreen {
    right: 0;
    bottom: 0;
    padding: 5px;
}

.viewer.mobile .guid_content_box {
    width: 202px;
    height: 115px;
    z-index: 9999;
}

.viewer.mobile .guid_content.fullscreen .guid_content_box {
    width: 100%;
    height: 100%;
}

.viewer.mobile .guid.scale {
    transform: scale(3);
}

.mobile.ant-modal {
    top: 20px;
}

.mobile.react_modal {
    inset: 10px;
    padding: 10px;
}

.mobile.react_modal .close {
    position: absolute;
    top: 5px;
    right: 5px;
}

.ant-modal-content-2.mobile {
    top: 5px;
    left: 5px;
    right: 5px;
}

.mobile .actions_bar .action {
    font-size: 20px;
    line-height: 35px;
}

.branding.mobile img {
    width: 100px;
}