@import '~antd/dist/antd.css';

#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}

.showcase {
    width: 100%;
    height: 100%;
    align-self: center;
    display: flex;
    border: 0;
}

.showcase_preview .showcase_container {
    height: 600px;
}

.showcase_container {
    height: 100%;
    position: relative;
}

.logo {
    padding: 5px 15px;
}

.logo > img {
    width: 100%;
}

.guid {
    position: fixed;
    right: -49px;
    bottom: 0;
    width: 368px;
    transition: transform .1s;
    transform-origin: bottom right;
    cursor: pointer;
}

.guid.scale {
    transform: scale(2);
    z-index: 999999999;
}

.guid_content {
    position: fixed;
    right: 30px;
    bottom: 70px;
    padding: 10px 10px 0 10px;
    border-radius: 5px;
    background-color: rgba(89, 89, 89, 0.5);
    color: white;
}


.guid_content.fullscreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px;
    backdrop-filter: blur(5px);
}

.guid_content.fullscreen .guid_content_box {
    width: 100%;
    height: 100%;
    background: none;
}

.guid_content_box {
    width: 256px;
    height: 150px;
    padding: 4px;
    background-color: rgb(89, 89, 89);
}

.guid_content_box.hide_guid_content_box {
    display: none;
}

.guid_content_box img.image_guide {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.guid_content iframe {
    width: 100%;
}

.triangle {
    width: 0;
    height: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    border-style: solid;
    border-width: 0 0 170px 415px;
    border-color: transparent transparent #ffffff transparent;
}

.react_modal {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    overflow: visible;

    position: absolute;
    inset: 40px;
    background: #fff;

    outline: none;
    padding: 20px;
}

.react_modal .close {
    position: absolute;
    top: -25px;
    right: -25px;
}

.viewer.guid_grid {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;
    position: fixed;
}

.next-btn.animate__heartBeat {
    /*--animate-duration: 1000ms;*/
    --animate-repeat: 10;
}

.intro_modal .intro_image {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.next-animation {
    animation: color-change 1s infinite;
}

@keyframes color-change {
    0% {
    }
    50% {
        color: #40a9ff;
        border-color: #40a9ff;
    }
    100% {
    }
}

.fullscreen_guid {
    position: absolute;
    right: -15px;
    top: -15px;
    transition: none;
}

.guid_content.fullscreen .fullscreen_guid {
    position: absolute;
    right: 10px;
    top: 10px;
}

.branding {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
}

.branding img {
    width: 151px;
}

.actions_bar {
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
}

.actions_bar .action + .action {
    border-left: solid 1px white;
}

.actions_bar .action {
    font-size: 30px;
    text-align: center;
    line-height: 50px;
    cursor: pointer;
    flex: 1;
}

.actions_bar .action.action-opacity {
    opacity: 0.5;
}

.guid_step_title {
    text-align: center;
    font-size: 15px;
    padding: 0 0 5px 0;
}

.ant-modal-content-2 {
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    width: auto !important;
    max-width: 550px;
    padding: 20px 30px;
    margin: 10px auto;
    top: 150px;
    position: relative;
}

.ant-modal-content-2 .ant-modal-content {
    background-color: transparent;
}

.ant-modal-content-2 .modal-title {
    font-size: 18px;
    margin-bottom: 30px;
}

.ant-modal-content-2 .modal-controls {
    display: flex;
    column-gap: 16px;
    justify-content: center;
}

.ant-modal-content-2 .modal-button {
    border: 1px solid #595959;
    padding: 5px 10px;
    display: inline;
    min-width: 150px;
    text-align: center;
    cursor: pointer;
    color: black;
    background-color: white;
}

.ant-modal-content-2 .modal-button:hover {
    background-color: #595959;
    color: white;
}

.ant-modal-content-2 .modal-dynamic-content {
    min-height: 100px;
}

.ant-modal-content-2 .modale-title-puce {
    height: 16px;
    position: relative;
    top: -1px;
    padding-right: 10px;
}

.animate_hide {
    display: none;
}

.hide_video_guid {
    position: absolute;
    top: -46px;
    right: 0;
    border-radius: 50%;
    background-color: rgba(89, 89, 89, 0.5);
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
}